<template>
  <div class="card-body mt-2 white">
    <div class="title">
      <i class="bi bi-menu-up"
        ><span style="font-size: 14px; margin-left: 5px; color: black"
          >รายละเอียด</span
        ></i
      >
    </div>

    <div class="body-text mt-5">
      <!--begin::Row-->
      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">หัวข้อเรื่อง</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.title }} </span>
        </div>

        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">คุณภาพของการให้บริการ 5 ดีสุด - 1 ดีน้อยสุด</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.sub_title }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Row-->
      <div class="row mb-7 mt-2 w-100">
        <!--begin::Label-->
        <label class="col-5 text-muted">รายละเอียด</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-7">
          <span class="fs-6 text-dark">{{ appeals.detail }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Appeal-Detail-Detail",
  props: ["appeals"],
});
</script>

<style scoped></style>
